<template>
    <div id="forget_password" :style="{'background-position': 'center center','background-image':'url('+backgroundImg(advert.image)+')','height':'604px'}">
        <div class="wrap">
			<div class="login_item">
				<div class="title">忘记密码</div>
				<div class="inputs">
					<img src="@/assets/img/login-01.png" alt="">
					<input type="text" @keyup.enter="submit" placeholder="手机" v-model="phone">
				</div>
				<div class="inputs">
					<i class="icon-font el-icon-picture"></i>
					<input type="text" @keyup.enter="submit" v-model="verifyCode" placeholder="图片验证码" maxlength="6">
                    <div class="imgCodeMode" @click="getVerifyCode" v-loading="verifysignLoding"><img :src="codeUrl" alt=""></div>
				</div>
				<div class="inputs">
					<i class="icon-font el-icon-message"></i>
					<input type="text" placeholder="手机验证码" maxlength="6" v-model="code">
                    <div class="pointer po1" @click="sendCode" v-if="verifysignShow">发送验证码</div>
                    <div class="pointer po2" v-else>{{getVerification}}</div>
				</div>
				<div class="inputs">
					<img src="@/assets/img/login-03.png" alt="">
					<input type="password" placeholder="密码" v-model="password">
				</div>
				<div class="inputs">
					<img src="@/assets/img/login-03.png" alt="">
					<input type="password" placeholder="确认密码" v-model="confirmPassword">
				</div>
				<div class="btn" @click="submit">确 定</div>
				<!-- <div class="other">
					<span @click="$router.push('/forget_password')">忘记密码</span>
					<span @click="$router.push('/register')">注册账号</span>
				</div> -->
			</div>
		</div>
    </div>
</template>

<script>
import rsa from '@/utils/rsa'

export default {
    name: '',
    data() {
        return {
            phone: '',
			password: '',
			confirmPassword:'',
			codeUrl: '',
			verifyCode: '',
			verifysign: '',
			verifysignLoding: false,
			verifysignShow: true,
			getVerification: '60s后重新获取',
			code:'',
			sign:'',
			loading:false,//防重复点击
			advert:{},
        }
    },
    created(){
		this.getVerifyCode();
		this.getAdvertList();
		// this.IsPC();
	},
    mounted(){},
    watch: {},
    methods: {
		IsPC(){  
            var userAgentInfo = navigator.userAgent;
            var Agents = new Array("Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod");  
            var flag = true;  
            for (var v = 0; v < Agents.length; v++) {  
                if (userAgentInfo.indexOf(Agents[v]) > 0) { flag = false; break; }  
            }  
            if(!flag){
                this.$router.push('/m_forgetpassword')
            }
        },
		backgroundImg(value){
            var host = document.location.host;
            var result = "";
            if(value && value.indexOf('files')!=-1 ){
                if(host.search('192')!=-1){
                    result = 'http://192.168.0.172:8080/' + value
                }else{
                    result = document.location.protocol + "//" + host + '/' + value
                }
            }else{
                result = value;
            }
            return result;
        },
		submit(){
			if(this.loading) return;
			this.loading = true;
			const loading = this.$loading();
			let reg = 11 && /^[1][0-9][0-9]{9}$/;
            if(!reg.test(this.phone)) {
				this.$message.warning('请输入正确的手机号码')
				this.loading = false;
				loading.close();
                return;
			};
			if(this.verifyCode == ''){
				this.$message.warning('请输入图片验证码')
				this.loading = false;
				loading.close();
				return
			}
			if(this.code == ''){
				this.$message.warning('请输入手机验证码')
				this.loading = false;
				loading.close();
				return
			}
			if(this.sign==''){
				this.$message.warning('请点击发送验证码');
				this.loading = false;
				loading.close();
				return
			}
			if(this.password == ''){
				this.$message.warning('请输入新密码')
				this.loading = false;
				loading.close();
				return
			}
			if(this.confirmPassword!=this.password){
				this.$message.warning('新密码与确认密码不一致')
				this.loading = false;
				loading.close();
				return
			}
			this.$request.post({
				url:'/bus/info/public/forgotPassword',
				params:{
					phone: rsa.encryptByPublicKey(this.phone),
					password: rsa.encryptByPublicKey(this.password),
					code: this.code,
					sign: this.sign
				},
				success:res => {
					this.$router.push('/login');
				},
				finally: () => {
					this.loading = false;
					loading.close();
				}
			})
		},
		getVerifyCode(){
            this.verifysignLoding = true
            this.$axios.get('/public/verifyCode?' + Math.random(),{responseType: 'arraybuffer'}).then(res => {
                let codeImg = 'data:image/png;base64,' + btoa(new Uint8Array(res.data).reduce((data,byte) => data + String.fromCharCode(byte), ''))
                this.codeUrl = codeImg
                this.verifysign = res.headers.sign
                this.verifysignLoding = false
            })
		},
		sendCode(){
            let reg = 11 && /^[1][0-9][0-9]{9}$/;
            if(!reg.test(this.phone)) {
                this.$message.warning('请输入正确的手机号码')
                return;
            };
            if(this.verifyCode == ''){
                this.$message.warning('请输入图片验证码')
                return
            }
            this.$request.post({
                url: '/sms/public/sendCode',
                params: {
                    phone: this.phone,
                    verifyCode: this.verifyCode,
                    verifySign: this.verifysign
                },
                success:res => {
                    this.sign = res
                    this.$message.success('发送成功')
                    let time = 60
                    this.verifysignShow = false
                    let Time = setInterval(() => {
                        if(time == 1){
                            this.verifysignShow = true
                            this.getVerifyCode()
                            clearInterval(Time)
                        }else{
                            time--
                            this.getVerification = `${time}s后重新获取`
                        }
                    },1000)
                }
            })
		},
		getAdvertList(){
            this.$request.post({
				url:'/ReportApi/business/advert',
				params:{
                    type:4,
					tenantId: localStorage.getItem("tenantInfoid")
				},
				success:res => {
					if(res.length>0){
						this.advert = res[0];
					}
				},
				finally: () => {
				}
			})
        },
	}
}
</script>

<style lang="scss" scoped>
#forget_password{
    background: rgb(233, 80, 46);
	.wrap{
		position: relative;
        img{
            display: block;
        }
	}
	.login_item{
		position: absolute;
		width: 350px;
		right: 0;
		top: 48px;
		background: #fff;
		padding: 30px;
		box-sizing: border-box;
		.title{
			text-align: center;
			font-size: 20px;
			color: #8c8c8c;
			line-height: 40px;
			margin-bottom: 30px;
		}
		.inputs{
            position: relative;
			margin-bottom: 15px;
			border: 1px solid #e1e1e1;
			height: 36px;
            .pointer{
                position: absolute;
                top: 0;
                right: 0;
                background: #f80;
                color: #fff;
                height: 36px;
                line-height: 36px;
                font-size: 14px;
                padding: 0 20px;
				cursor: pointer;
            }
			input{
				border: none;
				color: #666;
				outline: none;
				height: 36px;
				font-size: 16px;
				width: 240px;
				vertical-align: bottom;
			}
            .icon-font{
                font-size: 18px;
                margin: 8px;
                color: #cacaca;
            }
			img{
				width: 25px;
				display: inline-block;
				margin: 0 5px 2px;
			}
			.imgCodeMode{
                height: 36px;
                position: absolute;
                top: 0;
                right: 0;
                img{
                    margin: 0;
                    height: 100%;
                    width: auto;
                    display: block;
                }
                &:hover{
                    cursor: pointer;
                }
            }
		}
		.btn{
			width: 100%;
			line-height: 38px;
			background: #f80;
			color: #fff;
			text-align: center;
			border-radius: 4px;
			cursor: pointer;
		}
		.other{
			margin-top: 10px;
			font-size: 12px;
			color: #666;
			span{
				&:last-child{
					padding-left: 5px;
				}
				&:hover{
					cursor: pointer;
					color: #ff464e;
				}
			}
		}
	}
}
</style>